<template>
  <section id="my-profile-container" class="col-12 col-lg-6 my-2">
    <div class="row p-2">
      <div class="card-header card-header-tabs-line nav-tabs-line-3x col-12">
        <!--begin::Toolbar-->
        <div class="card-toolbar">
          <ul class="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
            <!-- Account Tab -->

            <li
              class="nav-item mr-3"
              @click="setCurrentTab('MyProfileAccountTab')"
            >
              <a
                class="nav-link"
                data-toggle="tab"
                href="#kt_user_edit_tab_2"
                :class="{
                  active: currentTabComponent === 'MyProfileAccountTab',
                }"
              >
                <span class="nav-icon">
                  <span class="svg-icon">
                    <!--begin::Svg Icon | path:/keen/theme/demo1/dist/assets/media/svg/icons/General/User.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="nav-text font-size-lg">Account</span>
              </a>
            </li>

            <!-- Change Password -->
            <li
              class="nav-item mr-3"
              @click="setCurrentTab('MyProfilePasswordTab')"
            >
              <a
                class="nav-link"
                data-toggle="tab"
                href="#kt_user_edit_tab_3"
                :class="{
                  active: currentTabComponent === 'MyProfilePasswordTab',
                }"
              >
                <span class="nav-icon">
                  <span class="svg-icon">
                    <!--begin::Svg Icon | path:/keen/theme/demo1/dist/assets/media/svg/icons/Communication/Shield-user.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                          fill="#000000"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="nav-text font-size-lg">Change Password</span>
              </a>
            </li>
          </ul>
        </div>
        <!--end::Toolbar-->
      </div>

      <!-- Render Account Tab or Password Tab -->
      <keep-alive>
        <component v-bind:is="currentTabComponent"></component>
      </keep-alive>
    </div>
  </section>
</template>

<script>
import MyProfileAccountTab from "@/components/MyProfile/MyProfileAccountTab.vue";
import MyProfilePasswordTab from "@/components/MyProfile/MyProfilePasswordTab.vue";
export default {
  name: "MyProfile",
  components: { MyProfileAccountTab, MyProfilePasswordTab },

  data() {
    return {
      currentTabComponent: "MyProfileAccountTab",
    };
  },

  methods: {
    setCurrentTab(tabName) {
      this.currentTabComponent = tabName;
    },
  },
};
</script>

<style lang="scss">
#my-profile-container {
  margin: auto;
  height: 50%;
  display: flex;
  flex-direction: column;

  .form-group {
    width: 100%;
  }

  .button-row {
    button {
      padding: 1rem;
      background: black;

      border: none;
      border-radius: 3px;
      margin: auto;
      width: 50%;
      span {
        color: white;
        color: white;
        font-weight: bold;
        font-family: "ForzaBold";
      }
    }
  }
}
</style>