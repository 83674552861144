<template>
  <form @submit.prevent="submitPassword" class="col-12 mt-2 mx-auto">
    <!--Password-->
    <div class="form-group row">
      <label class="col-form-label col-2 text-lg-left text-left"
        >Password</label
      >
      <div class="col-10">
        <input
          required
          v-model="password"
          class="form-control form-control-lg form-control-solid"
          type="password"
        />
      </div>
    </div>

    <!-- Repeat Password-->
    <div class="form-group row">
      <label class="col-form-label col-2 text-lg-left text-left"
        >Repeat Password</label
      >
      <div class="col-10">
        <input
          required
          v-model="repeatPassword"
          class="form-control form-control-lg form-control-solid"
          type="password"
        />
      </div>
    </div>

    <!-- Show Errors -->
    <div class="form-group row">
      <template class="col" v-for="err in Object.keys(errors)">
        <p v-if="errors[err]" :key="'err-' + errors[err]" class="error">
          {{ errors[err] }}
        </p>
      </template>
    </div>
    <!--Save -->
    <div class="form-group row button-row">
      <button class="col">
        <span v-if="!loading">Save Changes</span>
        <Loader v-else />
      </button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Utils/Loader.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "PasswordTab",
  components: { Loader },
  data() {
    return {
      password: "",
      repeatPassword: "",
      errors: {
        notEquals: false,
        short: false,
      },
      loading: false,
    };
  },

  computed: {
    ...mapState(["endpoint", "token"]),

    passwordAreEqueals() {
      return this.password === this.repeatPassword;
    },
    passwordIsShort() {
      return this.password.length < 4 || this.repeatPassword.length < 4;
    },
    hasNoErrors() {
      return !this.errors.notEquals && !this.errors.short;
    },
  },

  methods: {
    ...mapActions(["setToken"]),
    async submitPassword() {
      this.errors = {
        notEquals: false,
        short: false,
      };

      if (!this.passwordAreEqueals) {
        this.errors.notEquals = "The passwords are not equals";
      }
      if (this.passwordIsShort) {
        this.errors.short = "The password is too short";
      }

      if (this.hasNoErrors && !this.loading) {
        const params = {
          password: this.password,
          password_confirmation: this.repeatPassword,
          api_token: this.token,
        };

        try {
          this.loading = true;
          const url = this.endpoint + "/password";
          const { data } = await axios.post(url, params);
          await this.setToken(data);
          this.loading = false;
          this.$toastr.s("Password Changed!");
        } catch (error) {
          console.log(error);
          this.$toastr.e("There was an error");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
p.error {
  background: rgb(252, 61, 61);
  color: whitesmoke !important;
  border-radius: 0 0 5px 6px;
  padding: 0.5rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
}
</style>